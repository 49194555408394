<template>
  <div class="w-100">
    <!-- PDF Viewer -->
    <preflightPdfViewer v-if="pdfData" :pdfData="pdfData" :fileTotalPage="fileTotalPage" :pdfFile="pdfFile"
      :preflightId="preflightId" />

    <!-- Main Upload Area -->
    <div v-else class="main-container px-lg-4">
      <b-card class="mx-auto col-10 upload-card" v-if="!fileSelected">
        <!-- Upload Form Content -->
        <span class="upload-title">Upload a PDF</span>
        <b-form-file ref="fileUploadInput" @change="handleFileChange" multiple>
          <template slot="placeholder" slot-scope="{ names }">
            <div>
              <b-img src="./img/add-image.svg" class="upload-icon"></b-img>
              <p class="mb-0">
                <span class="preflight-text upload-text">Upload a PDF</span>
                <span class="preflight-text file-text"> or drag and drop </span>
              </p>
            </div>
          </template>
        </b-form-file>

        <!-- Error Message -->
        <div v-if="errorMessage" class="error-section my-3 d-flex align-items-center justify-content-start">
          <img src="/img/information-icon.svg" />
          <span class="preflight-text error-text"> {{ errorMessage }}</span>
        </div>

        <!-- Beta Feedback Section -->
        <div class="d-flex justify-content-between flex-wrap pt-2 gap-3">
          <div class="px-lg-2 d-flex align-items-lg-center gap-1">
            <div class="icon">
              <img src="/img/sparkles.svg" class="mb-0" width="20" height="20" alt="sparkle-icon" />
            </div>
            <span class="preflight-text beta-text">
              Preflight is in beta. We welcome your feedback!
            </span>
          </div>
          <button class="feedback-button" @click="handleFeedbackNavigation">
            <img src="/img/chat-alt.svg" width="20" height="20" alt="feedback-icon" />
            <span class="preflight-text feedback-text">Send Feedback</span>
          </button>
        </div>
      </b-card>

      <b-card v-else class="mx-auto col-10 upload-card">
        <!-- Error State -->
        <div v-if="apiError" class="api-error-section">
          <h4 class="error-title">There was an error processing your PDF</h4>
          <p class="mb-4" v-if="isStuck">This preflight has been processing for over an hour. Please try re-queuing the
            file.
          </p>
          <p class="mb-4" v-else>An error occurred while processing your file. Please try re-queuing.</p>
          <b-button @click="handleRequeue" class="requeue-button d-flex align-items-center mb-4">
            <span class="preflight-text requeue-text">Re-Queue</span>
            <img src="/img/duplicate-light.svg" alt="Re-Queue Icon" class="ms-2" />
          </b-button>
          <div class="d-flex feedback-section justify-content-between flex-wrap gap-3">
            <div class="px-12 d-flex align-items-center gap-1">
              <div class="icon">
                <img src="/img/sparkles.svg" class="mb-0" width="20" height="20" alt="sparkle-icon" />
              </div>
              <span class="preflight-text beta-text">
                Preflight is in beta. We welcome your feedback!
              </span>
            </div>
            <button class="feedback-button" @click="handleFeedbackNavigation">
              <img src="/img/chat-alt.svg" width="20" height="20" alt="feedback-icon" />
              <span class="preflight-text feedback-text">Send Feedback</span>
            </button>
          </div>
        </div>

        <!-- Loading State -->
        <div v-else-if="isLoading" class="d-flex flex-column align-items-center">
          <div class="pt-3">
            <img class="spinner" src="/img/spinner.gif" alt="Loading..." />
          </div>
          <div class="d-flex align-items-center loading-text-wrapper">
            <img src="/img/sparkles.svg" alt="sparkle-icon" class="mr-2" />
            <p class="transforming-text">Creating Your Print-Ready PDF...</p>
          </div>
        </div>

        <!-- Settings Form -->
        <div v-else>
          <h5 class="preflight-settings">Preflight Settings</h5>
          <div class=" border border-gray-200 bg-white  rounded position-relative overflow-hidden my-4">
            <div class="d-flex align-items-center justify-content-between  px-4 py-3">
              <span class="preflight-text file-name text-truncate" :title="fileName">{{ fileName }}</span>
              <button class="btn btn-link p-0 ms-2" @click="() => removeFile(true)">
                <img src="/img/trash-icon.svg" />
              </button>
            </div>
            <b-progress v-show="isUploading" :value="progress" max="100"
              class="w-100 modal-progress d-block"></b-progress>
          </div>

          <b-form>
            <b-row>
              <b-col cols="12" sm="6" class="field-left-margin">
                <b-form-group label="Height (Inches)" class="preflight-text form-group-custom">
                  <b-form-input v-model="preflightSettings.heightInches" type="number"
                    class="input-custom"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6" class="field-left-margin field-right-margin">
                <b-form-group label="Width (Inches)" class="preflight-text form-group-custom">
                  <b-form-input v-model="preflightSettings.widthInches" type="number"
                    class="input-custom"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="6" class="field-left-margin">
                <b-form-group label="Bleed (Inches)" class="preflight-text form-group-custom">
                  <b-form-input v-model="preflightSettings.bleedInches" type="number"
                    class="input-custom"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6" class="field-left-margin field-right-margin">
                <b-form-group label="Color Profile" class="preflight-text form-group-custom">
                  <b-form-select v-model="preflightSettings.colorProfile" :options="colorProfiles"
                    class="select-custom"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-end">
              <b-button @click="runPreflight" class="preflight-button d-flex justify-content-center align-items-center">
                <img :src="isRequeue ? '/img/duplicate-light.svg' : '/img/sparkle-white.svg'" class="mr-2" />
                <span class="preflight-text">{{ isRequeue ? 'Re-Queue Preflight' : 'Run Preflight' }}</span>
              </b-button>
            </div>
          </b-form>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/webpack';
import preflightPdfViewer from './preflightPdfViewer.vue';
import { FILE_TYPE } from '../../utils/constants';

export default {
  name: 'PreflightUpload',
  components: {
    preflightPdfViewer,
  },
  data() {
    return {
      fileSelected: false,
      fileName: '',
      errorMessage: '',
      isLoading: false,
      isUploading: false,
      progress: 0,
      apiError: false,
      preflightSettings: {
        heightInches: 0,
        widthInches: 0,
        bleedInches: 0.125,
        colorProfile: null,
      },
      colorProfiles: [],
      defaultColorProfile: null,
      pdfFile: null,
      pdfData: null,
      fileTotalPage: 0,
      preflightId: null,
      preflightStatus: null,
      queuedAt: null,
      isRequeue: false,
      pollingIntervalId: null,
    };
  },
  computed: {
    isStuck() {
      if (!this.queuedAt || this.preflightStatus !== 'Processing') return false;
      const queuedTime = new Date(`${this.queuedAt}Z`).getTime();
      const currentTime = Date.now();
      const oneHourInMs = 60 * 60 * 1000;
      return (currentTime - queuedTime) > oneHourInMs;
    }
  },
  methods: {
    async handleFileChange(event) {
      const files = event.target.files || event.dataTransfer.files
      const file = files && files[0];
      this.errorMessage = '';

      if (file) {
        this.$emit('loading', true);
        try {
          if (file.type !== FILE_TYPE.PDF) {
            this.removeFile();
            this.errorMessage = 'Please upload a PDF';
            return;
          }
          this.$emit('unsavedFiles', files);
          this.fileName = file.name;
          this.fileSelected = true;
          await this.extractPdfDimensions(file);
          this.pdfFile = file;
        } finally {
          this.$emit('loading', false);
        }
      }
    },
    async removeFile(addNewFile = false) {
      if (this.preflightId && addNewFile) {
        this.$emit('deleteFile', { id: this.preflightId })
      }

      this.fileName = '';
      this.fileSelected = false;
      this.errorMessage = '';
      this.isLoading = false;
      this.uploading = false;
      this.progress = 0;
      this.pdfFile = null;
      this.pdfData = null;
      this.apiError = false;
      this.fileTotalPage = 0;
      this.preflightId = null;
      this.clearPolling();
      if (addNewFile) this.$emit('addNewFile');
      this.$nextTick(
        () => this.$refs.fileUploadInput && this.$refs.fileUploadInput.reset(),
      );
    },
    updateUnsavedFiles() {
      const { heightInches, widthInches, bleedInches, colorProfile } = this.preflightSettings
      const file = {
        id: this.preflightId,
        fileName: this.fileName,
        colorProfile,
        bleedInches,
        widthInches,
        heightInches,
        status: this.preflightStatus
      }
      this.$emit('updateUnsavedFiles', file)
    },
    async updateFile(file) {
      this.fileName = file.fileName;
      this.fileSelected = true;
      this.preflightSettings.heightInches = file.heightInches || 0;
      this.preflightSettings.widthInches = file.widthInches || 0;
      this.preflightSettings.bleedInches = file.bleedInches || 0.125;
      this.preflightSettings.colorProfile = file.colorProfile || this.defaultColorProfile;
      this.preflightStatus = file.status;
      this.queuedAt = file.queuedAt;
      this.preflightId = file.id;
      this.isRequeue = file.isRequeue || false;

      if (!this.isRequeue) {
        if (file.status === 'Processing' && !this.isStuck) {
          this.isLoading = true;
          await this.pollPreflightStatus(file.id);
        } else if (this.isStuck) {
          this.apiError = true;
          this.isLoading = false;
        } else if (file.status === 'Completed') {
          this.isLoading = true;
          await this.getDownloadUrl(file.id);
          this.isLoading = false;
        }
      }
    },
    handleFeedbackNavigation() {
      window.open('https://ashoreapp.com/contact', '_blank');
    },
    async extractPdfDimensions(file, isUnsavedFile = false) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = async () => {
        const pdfData = new Uint8Array(fileReader.result);
        try {
          const pdf = await pdfjsLib.getDocument(pdfData).promise;
          this.fileTotalPage = pdf.numPages;
          const page = await pdf.getPage(1);
          const viewport = page.getViewport({ scale: 1 });
          this.preflightSettings.widthInches = parseFloat(
            (viewport.width / 72).toFixed(2),
          );
          this.preflightSettings.heightInches = parseFloat(
            (viewport.height / 72).toFixed(2),
          );
        } catch (error) {
          console.error('Error extracting PDF dimensions:', error);
        }
      };
      this.preflightSettings.bleedInches = 0.125;
      this.preflightSettings.colorProfile = this.defaultColorProfile;
      if (isUnsavedFile) {
        this.pdfFile = file;
        this.fileName = file.name;
        this.fileSelected = true;
      }
    },
    startProgress() {
      let interval = setInterval(() => {
        this.progress += 5;
        if (this.progress >= 95) {
          clearInterval(interval);
        }
      }, 100);
    },
    async runPreflight() {
      const { heightInches, widthInches, bleedInches, colorProfile } = this.preflightSettings;

      if (this.isRequeue) {
        // If requeuing, use the RequeuePreflight endpoint with updated settings
        this.isLoading = true;
        const response = await this.$A.PreflightService.RequeuePreflight(this.preflightId);
        if (response.success) {
          this.isRequeue = false;
          await this.pollPreflightStatus(this.preflightId);
        } else {
          this.apiError = true;
          this.isLoading = false;
        }
        return;
      }

      // Normal upload flow for new preflights
      const uploadRequest = {
        preflightUploadRequest: {
          fileName: this.fileName,
          heightInches,
          widthInches,
          bleedInches,
          colorProfile
        }
      }
      this.isUploading = true;
      this.startProgress();
      try {
        const response = await this.$A.PreflightService.GetPreflightUploadUrl(uploadRequest)
        if (response.uploadUrl) {
          this.preflightId = response.preflightId;
          this.updateUnsavedFiles();
          const uploadResponse = await this.uploadFileToPresignedUrl(response.uploadUrl, this.pdfFile);
          this.progress = 0;
          if (uploadResponse.success) {
            this.isUploading = false;
            this.isLoading = true;
            const preflightData = await this.$A.PreflightService.PreflightCompleteUpload(this.preflightId)
            if (preflightData.id) {
              await this.pollPreflightStatus(this.preflightId)
            }
          } else {
            this.apiError = true;
            this.isLoading = false;
            this.isUploading = false;
          }
        } else {
          this.apiError = true;
          this.isUploading = false;
        }
      } catch (error) {
        console.error('Error during preflight upload:', error);
        this.apiError = true;
        this.isLoading = false;
        this.isUploading = false;
      }
    },
    async pollPreflightStatus(preflightId) {
      const pollingInterval = 5000;
      const maxRetries = 36;
      let attempts = 0;

      this.pollingIntervalId = setInterval(async () => {
        try {
          attempts++;
          const preflightData = await this.$A.PreflightService.GetPreflight(preflightId);

          if (!preflightData) {
            console.error('Preflight not found or error occurred');
            this.apiError = true;
            this.isLoading = false;
            clearInterval(interval);
            return;
          }

          this.preflightStatus = preflightData.status;
          this.queuedAt = preflightData.queuedAt;

          if (preflightData.status === 'Completed') {
            await this.getDownloadUrl(this.preflightId);
            this.isLoading = false;
            clearInterval(this.pollingIntervalId);
          } else if (this.isStuck || attempts >= maxRetries) {
            console.log('Max polling attempts reached or preflight is stuck');
            this.apiError = true;
            this.isLoading = false;
            clearInterval(this.pollingIntervalId);
          }
        } catch (error) {
          console.error('Error polling preflight status:', error);
          this.apiError = true;
          this.isLoading = false;
          clearInterval(this.pollingIntervalId);
        }
      }, pollingInterval);
    },
    clearPolling() {
      if (this.pollingIntervalId) clearInterval(this.pollingIntervalId);
    },
    async uploadFileToPresignedUrl(uploadUrl, file) {
      try {
        const response = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
        });

        if (response.ok) {
          return { success: true };
        } else {
          console.error('Error uploading file:', response.statusText);
          return { success: false };
        }
      } catch (error) {
        console.error('Error in file upload request:', error);
        return { success: false };
      }
    },
    async getPdfFileDetails(url) {
      try {
        const response = await fetch(url);
        const pdfBlob = await response.blob();
        const pdfArrayBuffer = await pdfBlob.arrayBuffer();
        const pdfData = new Uint8Array(pdfArrayBuffer);
        this.pdfFile = new File([pdfBlob], this.fileName, { type: pdfBlob.type });
        window.$A.preflightPdf = this.pdfFile;
        this.extractPdfDimensions(this.pdfFile)
        this.pdfData = pdfData;
      } catch (error) {
        console.error('Error during fetching file', error);
      }
    },
    async getDownloadUrl(id) {
      const response = await this.$A.PreflightService.GetPreflightDownloadUrl(id)
      if (response) await this.getPdfFileDetails(response)
    },
    async handleRequeue() {
      this.apiError = false;
      // Get the current preflight data to show settings form
      const preflightData = await this.$A.PreflightService.GetPreflight(this.preflightId);
      if (preflightData) {
        // Set requeue mode and use existing settings
        preflightData.isRequeue = true;
        this.updateFile(preflightData);
      } else {
        this.apiError = true;
        window.$A.AlertUser('Error loading preflight data', 'error');
      }
    },
    async getColorProfiles() {
      try {
        const response = await this.$A.PreflightService.GetPreflightColorProfiles();
        if (!response) return;

        const rgbProfiles = response.rgb.profiles;
        const cmykProfiles = response.cmyk.profiles;
        const mergedProfiles = [
          ...Object.keys(rgbProfiles).map(profileName => ({
            value: rgbProfiles[profileName],
            text: profileName,
          })),
          ...Object.keys(cmykProfiles).map(profileName => ({
            value: cmykProfiles[profileName],
            text: profileName,
          }))
        ];
        this.colorProfiles = mergedProfiles;
        this.defaultColorProfile = response.defaultProfile;
        this.preflightSettings.colorProfile = response.defaultProfile;
      } catch (error) {
        console.error('Error loading color profiles:', error);
      }
    }
  },
  async mounted() {
    // Only load color profiles if they haven't been loaded yet
    if (this.colorProfiles.length === 0) {
      await this.getColorProfiles();
    }
  }
};
</script>

<style scoped>
.main-container {
  padding-top: 64px;
  margin-bottom: 64px;
}

.preflight-text {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
}

.upload-card {
  border-radius: 8px;
}

.upload-title {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.upload-icon {
  margin-bottom: 4px;
}

.upload-text {
  color: #006f41;
}

.file-text {
  color: #575759;
}

::v-deep .custom-file-label,
.form-control-file {
  border: none;
  border-radius: 5px;
  background-color: #ffff;
  padding: 20px 0px;
  margin-bottom: 10px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23b5b5b8' stroke-width='4' stroke-dasharray='4%2c 12' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
}

::v-deep .col {
  padding-right: 0px;
  padding-left: 0px;
}

.field-left-margin {
  padding-left: 15px
}

.field-right-margin {
  padding-right: 15px
}

.info-upload-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #909092;
}

.error-section {
  height: 52px;
  padding: 16px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #f89aa2;
  background-color: #fcf0f1;
}

.error-text {
  color: #a62934;
}

.beta-text {
  font-size: 12px;
  line-height: 16px;
  color: #1b1b1c;
}

.feedback-section {
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  background-color: #F0F9F4;
  border: 1px solid #34A262;
}

.feedback-button {
  width: 159px;
  height: 38px;
  padding: 9px 17px 9px 15px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px #080b0d0d;
  background-color: #ffff;
}

.feedback-button:hover {
  background-color: #e0e0e0;
}

.feedback-text {
  padding-left: 6px;
  color: #374151;
}

.preflight-settings {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: 'from-font';
  text-decoration-skip-ink: none;
  color: #1b1b1c;
}

.file-wrapper {
  height: 56px;
  border-radius: 4px;
  border: 1px solid #d5d5d8;
  padding: 0 24px;
  margin: 24px 0;
  box-shadow: 0px 1px 2px 0px #080b0d0d;
}

.file-name {
  color: #444444;
}

.form-group-custom {
  margin-bottom: 1.5rem;
  color: #3e3e40;
}

.input-custom,
.select-custom {
  font-family: 'Open Sans';
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  height: 42px;
  padding: 9px 13px 9px 13px;
  color: #1b1b1c;
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  box-shadow: 0px 1px 2px 0px #080b0d0d;
}

.select-custom {
  padding-right: 2.25rem;
}

.preflight-button {
  padding: 9px 17px 9px 15px;
  border-radius: 6px;
  background: #006f41;
  box-shadow: 0px 1px 2px 0px #080b0d0d;
  transition: all 0.3s ease;
}

.preflight-button:hover {
  background-color: #00562f;
  border-color: #00562f;
}

.spinner {
  width: 116px;
  height: 116px;
}

.transforming-text {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #1b1b1c;
  margin: 0;
}

.loading-text-wrapper {
  margin-top: 24px;
  margin-bottom: 12px;
}

.api-error-section {
  padding: 12px 0;
}

.requeue-button {
  padding: 9px 15px 9px 17px;
  gap: 8px;
  border-radius: 6px;
  background-color: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px 0px #080B0D0D;
  transition: all 0.3s ease;
}

.requeue-button:hover {
  background-color: #E6E6E6;
}

.error-title {
  font-size: 16px;
  color: #1B1B1C;
}

.requeue-text {
  color: #3E3E40;
}

.modal-progress {
  height: 2px;
  border-radius: 0px;
  padding-top: 16px;
  padding-right: 0px;
  margin-bottom: 0px !important;
  background-color: #F4F4F6;
}

.transforming-progress {
  width: 100%;
  height: 8px !important;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

::v-deep .progress-bar {
  height: 100%;
  border-radius: 5px;
  transition: width 0.2s ease-in-out;
  background: linear-gradient(90deg, rgba(1, 146, 86, 0.7) 0%, rgba(1, 146, 86, 0.3) 50%, rgba(1, 146, 86, 0.7) 100%);
  background-size: 200% 100%;
  animation: gradientMove 2s linear infinite;
}

::v-deep .progress-bar-striped {
  background-image: none;
}

::v-deep .custom-select {
  background-image: url('/img/review/icons/chevron-down.svg') !important;
  background-size: 20px 20px;
}

::v-deep .card-body {
  padding: 32px 20px;
}

@keyframes gradientMove {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

@media(max-width : 992px) {
  ::v-deep .card-body {
    padding: 20px 5px;
  }
}
</style>
